import React from 'react'

import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Link } from 'react-router-dom'

export default function Photography() {
  const portadas = [
  {
  id:"camp", 
  url:"/png/photos/CAMP/CAMP_3.jpg",
  href:"/camp"
  },
  {
  id:"winter", 
  url:"/png/photos/WINTER/WINTER_1.jpg",
  href:"/winter"
  },
  {
  id:"days", 
  url:"/png/photos/DAYS/DAYS_1.jpg",
  href:"/days"
  },
  {
  id:"acuanisele", 
  url:"/png/photos/ACUANISELE/ACUANISELE_1.jpg",
  href:"/acuanisele"
  },
]


  return (  
    <div className='flex flex-col w-full h-full relative   bg-primary-500 '>
     <img className=" w-full h-full select-none object-cover aspect-[4/3]  absolute left-0 top-0  z-10 flex test" loading="lazy" src={"/png/bg_large.png"} alt={"bg"} />
      
        <Navbar />
     <div className='flex flex-col text-black gap-12  items-center   pt-10  pb-20'>

       <Portada_Interna
        title={"Photographys"}
      
        description={"In the following I will describe the research projects I am currently involved in. Each is an ongoing project, and while all of them have preliminary results, I am super open to collaborate, so reach out to me if interested!        "}
        />

     <ButtonUp/>

    <ResponsiveMasonry className='w-full wrapper relative z-10 lg:px-28 xl:px-0'
      columnsCountBreakPoints={{350: 1, 750: 2, 1280: 3}}
            >
                <Masonry gutter="20px">
               
                {portadas.map((image) => (
                 <Link to={`/photography/${image.id}`} key={image.id}
                 className='relative flex overflow-hidden h-full  w-full items-center justify-center bg-gradient-to-b from-black/30 to-black/10 group ' 
                   >
                      <img
                            loading='lazy'
                            className='relative z-0 h-full w-full aspect-[4/3]  object-cover brightness-50 opacity-80 group-hover:opacity-100'
                            src={image.url}
                          />
                      
                      <p className='absolute z-10 font-bold text-3xl uppercase text-primary-100 group-hover:text-white'>{image.id}</p>
                    
                 </Link>
                ))}
                </Masonry>
    </ResponsiveMasonry>
    </div>
      <Footer/>
    </div>
    
  )
}
