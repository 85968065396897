import React from 'react'

const Seccion_ImgDerecha = ({id, title,link0, link1,link2,href1, href2, phg1, phg2, phg3, phg4, phg5, phg6, img,imgAlt, }) => {
  return (
    <div id={id} className='flex flex-col gap-4 h-full w-full wrapper relative z-10 pt-20'>
        <h3>{title}</h3>
        <div className='flex flex-col  gap-6 w-full h-full'>
           <div className='h-full flex flex-col-reverse lg:flex-row  gap-4'>
             
            <div className='flex flex-col w-full lg:w-1/2 xl:w-full gap-6 '>
                <p>{phg1}</p>
                <p>{phg2}</p>
               {phg3 ? <p>{phg3}</p>: null}
               {phg4 ? <p>{phg4}</p>: null}
            </div>

              
             
           
            <div className='flex justify-center items-center relative self-center lg:self-auto w-1/2 xl:w-full h-full '>
              <img className='absolute z-0 blur-xl self-center w-full  h-auto aspect-[4/3] object-contain' src={img} alt={imgAlt+"1"} />
              <img className='relative  z-10 self-center w-full  h-full aspect-[4/3] object-contain' src={img} alt={imgAlt+"1"} />
            </div>
           </div>

           <div className='flex flex-col gap-6 '>
             {phg5 ? <p>{phg5}</p>: null}
             
              <ul className=' list-inside list-disc'>
                  <p className='font-semibold'>{phg6}</p>
                  {link0 ? <li>{link0}</li>: null}
                  { link1? <li> <a className="underline" target="_blank"  href={href1}>{link1}</a></li>: null}
                    { link2 ?<li> <a className="underline" target="_blank" href={href2}>{link2}</a></li>: null}
               </ul>
            </div>
        </div>
    </div>
  )
}

export default Seccion_ImgDerecha