import React, { useEffect, useState } from 'react';
import Portada from '../components/portada'
import Footer from '../components/footer';
import Navbar from '../NavBar';
import { Link } from 'react-router-dom';

const useHeightBasedImage = () => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      const handleResize = () => {
        const { innerHeight } = window;
        
        if (innerHeight < 800) {
          setImageUrl('/png/bg_miro.png');
        } else {
          setImageUrl('/png/bg_miro.png');
        }
      };
  
      handleResize(); // Se ejecuta al cargar la página
  
      window.addEventListener('resize', handleResize); // Se suscribe al evento de cambio de tamaño de ventana
  
      return () => {
        window.removeEventListener('resize', handleResize); // Se limpia el evento al desmontar el componente
      };
    }, []);
  
    return imageUrl;
  };


function NotFount()  {
  
    const imageUrl = useHeightBasedImage();

    return (
        
   <div className='flex flex-col   w-full h-screen md:overflow-hidden bg-primary-500 relative'>
    <img
            className=" w-full h-full lg:h-auto select-none object-cover aspect-[4/3] absolute left-0 top-0
            lg:bottom-0  z-10 flex test"
            loading="lazy"
            src={"./png/bg_miro.png"}
        />
     <div className='w-full  h-full flex items-center justify-center overflow-hidden relative z-10  '>
      <div className='flex flex-col gap-10 items-center justify-center'>
        <h2 className='font-bold'>Ups. Page not found</h2>

        <Link to={"/"} className="border border-black bg-secondary-500 hover:bg-secondary-600 text-black  px-8 py-1 rounded-2xl">Go back</Link>

      </div>
           
     </div>
   </div>
    );
    }

export default NotFount;