import React, { useState } from "react";
import {BrowserRouter, Route,Link} from "react-router-dom";

function Navbar(){
    const links = [
    {
    name:"Physics", 
    href:"/physics"
    },
    {
    name:"Photography", 
    href:"/photography"
    },
    {
    name:"Science & Arts", 
    href:"/science_arts"
    },
    {
    name:"Contact", 
    href:"/contact"
    },
    ]
  
    const [open, setOpen] = useState(false);

    const controlMenu = (toggle) => {
        setOpen(!toggle)
    }
return(
    <nav className="font-urbanist  z-20 flex flex-row items-center w-full  px-8 md:px-12 lg:px-28   justify-center  bg-primary-600 text-black ">
        <div className="flex flex-row w-full justify-between max-w-7xl">
        <Link className="text-2xl md:flex  xl:text-2xl font-semibold py-8 hover:font-extrabold  "to="/">Matías Bilkis</Link>
      
        <ul className="hidden lg:flex  lg:flex-row gap-2 lg:gap-10 text-xl  font-semibold   items-center  ">
                {links.map((link) => {
                    return (
                    <li className="text-sm lg:text-lg group " key={link.name}>
                        <Link to={link.href}>{link.name}
                        <div className="w-full h-0.5 bg-black"></div>
                      </Link>
                    </li>
                    )
                })}
        </ul>
        </div>

        <button className={open?"hidden":"flex py-8 lg:hidden"} onClick={()=>controlMenu(open)}>
             <svg className="text-black" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.375 20C34.375 20.1658 34.3092 20.3247 34.1919 20.4419C34.0747 20.5592 33.9158 20.625 33.75 20.625H6.25C6.08424 20.625 5.92527 20.5592 5.80806 20.4419C5.69085 20.3247 5.625 20.1658 5.625 20C5.625 19.8342 5.69085 19.6753 5.80806 19.5581C5.92527 19.4408 6.08424 19.375 6.25 19.375H33.75C33.9158 19.375 34.0747 19.4408 34.1919 19.5581C34.3092 19.6753 34.375 19.8342 34.375 20ZM6.25 10.625H33.75C33.9158 10.625 34.0747 10.5592 34.1919 10.4419C34.3092 10.3247 34.375 10.1658 34.375 10C34.375 9.83424 34.3092 9.67527 34.1919 9.55806C34.0747 9.44085 33.9158 9.375 33.75 9.375H6.25C6.08424 9.375 5.92527 9.44085 5.80806 9.55806C5.69085 9.67527 5.625 9.83424 5.625 10C5.625 10.1658 5.69085 10.3247 5.80806 10.4419C5.92527 10.5592 6.08424 10.625 6.25 10.625ZM33.75 29.375H6.25C6.08424 29.375 5.92527 29.4408 5.80806 29.5581C5.69085 29.6753 5.625 29.8342 5.625 30C5.625 30.1658 5.69085 30.3247 5.80806 30.4419C5.92527 30.5592 6.08424 30.625 6.25 30.625H33.75C33.9158 30.625 34.0747 30.5592 34.1919 30.4419C34.3092 30.3247 34.375 30.1658 34.375 30C34.375 29.8342 34.3092 29.6753 34.1919 29.5581C34.0747 29.4408 33.9158 29.375 33.75 29.375Z" fill="currentColor"/>
            </svg>

        </button>
        <ul className={open?"flex lg:hidden  flex-col gap-4 lg:gap-10 font-bold text-black bg-primary-600 h-screen absolute right-0 top-0 p-8 items-start z-50  ":"hidden"}>
             <button className="self-end"  onClick={()=>controlMenu(open)}>
            
                        <svg className="text-black" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.692 30.8082C31.8093 30.9254 31.8752 31.0845 31.8752 31.2503C31.8752 31.4162 31.8093 31.5753 31.692 31.6925C31.5748 31.8098 31.4157 31.8757 31.2499 31.8757C31.084 31.8757 30.9249 31.8098 30.8077 31.6925L19.9999 20.8847L9.19205 31.6925C9.07477 31.8098 8.91571 31.8757 8.74986 31.8757C8.58401 31.8757 8.42495 31.8098 8.30767 31.6925C8.1904 31.5753 8.12451 31.4162 8.12451 31.2503C8.12451 31.0845 8.1904 30.9254 8.30767 30.8082L19.1155 20.0003L8.30767 9.19254C8.1904 9.07526 8.12451 8.9162 8.12451 8.75035C8.12451 8.5845 8.1904 8.42544 8.30767 8.30816C8.42495 8.19088 8.58401 8.125 8.74986 8.125C8.91571 8.125 9.07477 8.19088 9.19205 8.30816L19.9999 19.116L30.8077 8.30816C30.8657 8.25009 30.9347 8.20403 31.0105 8.1726C31.0864 8.14117 31.1677 8.125 31.2499 8.125C31.332 8.125 31.4133 8.14117 31.4892 8.1726C31.565 8.20403 31.634 8.25009 31.692 8.30816C31.7501 8.36623 31.7962 8.43517 31.8276 8.51104C31.859 8.58691 31.8752 8.66823 31.8752 8.75035C31.8752 8.83247 31.859 8.91379 31.8276 8.98966C31.7962 9.06553 31.7501 9.13447 31.692 9.19254L20.8842 20.0003L31.692 30.8082Z" fill="currentColor"/>
                        </svg>

             </button>
                {links.map((link) => {
                    return (
                    <li className="" key={link.name}>
                        <Link className="group" to={link.href}>{link.name}
                        <div className="group-hover:translate-x-0 -translate-x-full w-full h-0.5 bg-black"></div>
                        </Link>
                    </li>
                    )
                })}
        </ul>

    </nav>
);
}

export default Navbar;