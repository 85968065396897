import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../NavBar";
import Footer from "../components/footer";

const Layout=({isHome})=>{
return(
    <div className={"font-urbanist"}>
      
        <Outlet/>

    </div>
);
};

export default Layout;