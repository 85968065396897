import React, { useEffect, useState } from 'react'

export default function ButtonUp() {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
          const position = window.pageYOffset;
          setScrollPosition(position);
        };
      
        window.addEventListener("scroll", handleScroll, { passive: true });
      
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
  return (
    <button
    className={`scroll-to-top ${scrollPosition > 100 ? "show rounded-full"  : "hide"}`}
    onClick={scrollToTop}
  >
    <svg className='w-12 h-12 bg-primary-500 text-primary-900 rounded-full' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 4.0625C16.8479 4.0625 13.7665 4.99722 11.1456 6.74845C8.5247 8.49969 6.48195 10.9888 5.27568 13.901C4.06941 16.8132 3.75379 20.0177 4.36874 23.1093C4.98369 26.2008 6.50159 29.0406 8.73049 31.2695C10.9594 33.4984 13.7992 35.0163 16.8908 35.6313C19.9823 36.2462 23.1868 35.9306 26.099 34.7243C29.0112 33.5181 31.5003 31.4753 33.2516 28.8544C35.0028 26.2335 35.9375 23.1521 35.9375 20C35.9325 15.7746 34.2518 11.7238 31.264 8.73597C28.2763 5.74818 24.2254 4.06746 20 4.0625ZM20 34.0625C17.2187 34.0625 14.4999 33.2377 12.1873 31.6925C9.87473 30.1473 8.07231 27.9511 7.00795 25.3815C5.94359 22.8119 5.66511 19.9844 6.20771 17.2565C6.75032 14.5287 8.08964 12.023 10.0563 10.0563C12.023 8.08963 14.5287 6.75031 17.2565 6.20771C19.9844 5.6651 22.8119 5.94359 25.3815 7.00794C27.9511 8.0723 30.1473 9.87473 31.6925 12.1873C33.2378 14.4999 34.0625 17.2187 34.0625 20C34.0584 23.7283 32.5755 27.3028 29.9391 29.9391C27.3028 32.5755 23.7283 34.0584 20 34.0625ZM25.6625 18.0875C25.7546 18.1733 25.8285 18.2768 25.8797 18.3918C25.931 18.5068 25.9585 18.631 25.9607 18.7568C25.963 18.8827 25.9398 19.0078 25.8927 19.1245C25.8455 19.2412 25.7753 19.3473 25.6863 19.4363C25.5973 19.5253 25.4912 19.5955 25.3745 19.6427C25.2578 19.6898 25.1327 19.713 25.0069 19.7107C24.881 19.7085 24.7568 19.681 24.6418 19.6297C24.5268 19.5785 24.4233 19.5046 24.3375 19.4125L20.9375 16.0141V26.25C20.9375 26.4986 20.8387 26.7371 20.6629 26.9129C20.4871 27.0887 20.2486 27.1875 20 27.1875C19.7514 27.1875 19.5129 27.0887 19.3371 26.9129C19.1613 26.7371 19.0625 26.4986 19.0625 26.25V16.0141L15.6625 19.4125C15.4848 19.5781 15.2497 19.6683 15.0069 19.664C14.764 19.6597 14.5322 19.5613 14.3605 19.3895C14.1887 19.2178 14.0903 18.986 14.086 18.7432C14.0818 18.5003 14.1719 18.2652 14.3375 18.0875L19.3375 13.0875C19.5133 12.9119 19.7516 12.8133 20 12.8133C20.2484 12.8133 20.4867 12.9119 20.6625 13.0875L25.6625 18.0875Z" fill="currentColor"/>
    </svg>


  </button>
  
  )
}
