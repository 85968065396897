import ReactDOM from "react-dom";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import './index.css'
import Physics from "./pages/Physics";
import Photography from "./pages/Photography";
import DynamicPhotography from "./pages/DynamicPhotography";
import Contact from "./pages/Contact";
import ScienceArt from "./pages/ScienceArt";
import NotFount from "./pages/404";

export default function App(){
    const pages = [
        { 
          id: "camp",
          images: [
            "/png/photos/CAMP/CAMP_1.jpg", 
            "/png/photos/CAMP/CAMP_2.jpg",
            "/png/photos/CAMP/CAMP_3.jpg",
            "/png/photos/CAMP/CAMP_4.jpg",
            "/png/photos/CAMP/CAMP_5.jpg",
            "/png/photos/CAMP/CAMP_6.jpg",
            "/png/photos/CAMP/CAMP_7.jpg",
            "/png/photos/CAMP/CAMP_8.jpg",
            "/png/photos/CAMP/CAMP_9.jpg",
            "/png/photos/CAMP/CAMP_10.jpg",
            "/png/photos/CAMP/CAMP_11.jpg",
            "/png/photos/CAMP/CAMP_12.jpg",
            "/png/photos/CAMP/CAMP_13.jpg",
            "/png/photos/CAMP/CAMP_14.jpg",
            "/png/photos/CAMP/CAMP_15.jpg",
            "/png/photos/CAMP/CAMP_16.jpg",
            "/png/photos/CAMP/CAMP_17.jpg",
            "/png/photos/CAMP/CAMP_18.jpg",
            "/png/photos/CAMP/CAMP_19.jpg",
            "/png/photos/CAMP/CAMP_20.jpg",
            "/png/photos/CAMP/CAMP_21.jpg",
            "/png/photos/CAMP/CAMP_22.jpg",
            "/png/photos/CAMP/CAMP_23.jpg",
            "/png/photos/CAMP/CAMP_24.jpg",
            "/png/photos/CAMP/CAMP_25.jpg",
            "/png/photos/CAMP/CAMP_26.jpg",
            "/png/photos/CAMP/CAMP_27.jpg",
            "/png/photos/CAMP/CAMP_28.jpg",


          ]
        },
        { id: "winter",
        images: [
            "/png/photos/WINTER/WINTER_1.jpg", 
            "/png/photos/WINTER/WINTER_2.jpg",
            "/png/photos/WINTER/WINTER_3.jpg",
            "/png/photos/WINTER/WINTER_4.jpg",
            "/png/photos/WINTER/WINTER_5.jpg",
            "/png/photos/WINTER/WINTER_6.jpg",
            "/png/photos/WINTER/WINTER_7.jpg",
            "/png/photos/WINTER/WINTER_8.jpg",
            "/png/photos/WINTER/WINTER_9.jpg",
            "/png/photos/WINTER/WINTER_10.jpg",
            "/png/photos/WINTER/WINTER_11.jpg",
            "/png/photos/WINTER/WINTER_12.jpg",
            "/png/photos/WINTER/WINTER_13.jpg",
            "/png/photos/WINTER/WINTER_14.jpg",
            "/png/photos/WINTER/WINTER_15.jpg",
           
          ]
        },
        { id: "days",
        images: [
          "/png/photos/DAYS/DAYS_1.jpg", 
          "/png/photos/DAYS/DAYS_2.jpg",
          "/png/photos/DAYS/DAYS_3.jpg",
          "/png/photos/DAYS/DAYS_4.jpg",
          "/png/photos/DAYS/DAYS_5.jpg",
          "/png/photos/DAYS/DAYS_6.jpg",
          "/png/photos/DAYS/DAYS_7.jpg",
          "/png/photos/DAYS/DAYS_8.jpg",
          "/png/photos/DAYS/DAYS_9.jpg",
          "/png/photos/DAYS/DAYS_10.jpg",
          "/png/photos/DAYS/DAYS_11.jpg",
          "/png/photos/DAYS/DAYS_12.jpg",
          "/png/photos/DAYS/DAYS_13.jpg",
          "/png/photos/DAYS/DAYS_14.jpg",
          "/png/photos/DAYS/DAYS_15.jpg",
          "/png/photos/DAYS/DAYS_16.jpg",
          "/png/photos/DAYS/DAYS_17.jpg",
        ]
    
        },
        { id: "acuanisele",
        images: [
            "/png/photos/ACUANISELE/ACUANISELE_1.jpg", 
            "/png/photos/ACUANISELE/ACUANISELE_2.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_3.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_4.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_5.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_6.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_7.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_8.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_9.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_10.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_11.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_12.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_13.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_14.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_15.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_16.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_17.jpg",
            "/png/photos/ACUANISELE/ACUANISELE_18.jpg",
          ]
    
        }
        // Agrega más páginas si es necesario
      ];

return(
<BrowserRouter>
<Routes>
<Route path="/" element={<Layout isHome={true}/>}>
<Route index element={<Home/>}/>
<Route path="physics" element={<Physics/>}/>
<Route path="photography" element={<Photography/>}/>

        {pages.map((page) => (
          <Route
            key={page.id}
            path={`/photography/${page.id}`}
            element={<DynamicPhotography id={page.id} images={page.images} />}
          />
        ))}
  <Route path="science_arts" element={<ScienceArt/>}/>    
<Route path="contact" element={<Contact/>}/>
<Route path="*" element={<NotFount/>} />
</Route>
</Routes>
</BrowserRouter>
);
}

ReactDOM.render(<App />,document.getElementById("root"));