import React, { useState, useEffect, useRef } from 'react';

const ProgressBar = ({linksList}) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const updateScrollPercentage = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight - windowHeight;
    const scrolledHeight = window.scrollY;
    const percentage = (scrolledHeight / fullHeight) * 100;
    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollPercentage);
    return () => {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);


  const elementRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const checkSticky = () => {
      const element = elementRef.current;
      if (element) {
        const { top } = element.getBoundingClientRect();
        setIsSticky(top <= 0);
      }
    };

    window.addEventListener('scroll', checkSticky);
    return () => {
      window.removeEventListener('scroll', checkSticky);
    };
  }, []);


  return (
    <div ref={elementRef} className={isSticky ? "flex flex-col gap-4 justify-center  w-full h-full  sticky top-0 z-40   bg-primary-600 drop-shadow shadow-black":'bg-transparent flex flex-col gap-4 justify-center  w-full h-full sticky top-0 z-10   '}>
        <ul className='flex flex-row font-bold text-sm lg:text-lg gap-10 items-center justify-center text-center self-center px-8 lg:px-28 xl:px-0'>
            {linksList.map((link)=>{
                return(
                    <li><a className='hover:opacity-70' href={link.href}>{link.name}</a></li>
                )
            })}
        </ul>
       <div>
        <div className={isSticky ? "progress-bar" : null} style={{ width: `${scrollPercentage}%` }}>
            </div>
           
       </div>
       
    </div>
  );
};

export default ProgressBar;
