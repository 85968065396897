import React from 'react'

const Seccion_ImgVideo = ({id, title,link0, link1,link2,href1, href2, phg1, phg2, phg3, phg4, phg5, phg6, video1,video2,imgAlt,gitRepo}) => {
  return (
    <div id={id} className='flex flex-col gap-4 h-full w-full wrapper relative z-10 pt-20'>
        <h3>{title}</h3>
        <div className='flex flex-col  gap-6 w-full h-full'>
           <div className='h-full flex flex-col lg:flex-row  gap-4'>
             
            <div className='flex flex-col w-full lg:w-1/2 xl:w-full gap-6 '>
                <p>{phg1}</p>
                <p>{phg2}</p>
                {phg3 ? <p>{phg3}</p>: null}
                {phg4 ? <p>{phg4}</p>: null}
            </div>

              
             
           
            <div className='flex justify-center items-center relative self-center lg:self-auto w-full lg:w-1/2 xl:w-full h-full  '>
              <iframe className='w-full h-full rounded-2xl overflow-hidden aspect-video' width="560" height="315" src={video1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
           </div>

           <div className='flex flex-col gap-6 w-full relative'>
             {phg5 ? <p>{phg5}</p>: null}
           
           

              <ul className=' list-inside list-disc'>
                  <p className='font-semibold'>{phg6}</p>

                  {gitRepo ? 

                  <a href={gitRepo} className='flex flex-row gap-2 items-center'>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M39.6721 20C39.6721 30.8646 30.8646 39.6721 20 39.6721C9.13538 39.6721 0.327869 30.8646 0.327869 20C0.327869 9.13538 9.13538 0.327869 20 0.327869C30.8646 0.327869 39.6721 9.13538 39.6721 20Z" stroke="black" stroke-width="0.655738"/>
                 <path d="M20 10C18.6868 10 17.3864 10.2587 16.1732 10.7612C14.9599 11.2638 13.8575 12.0003 12.9289 12.9289C11.0536 14.8043 10 17.3478 10 20C10 24.42 12.87 28.17 16.84 29.5C17.34 29.58 17.5 29.27 17.5 29V27.31C14.73 27.91 14.14 25.97 14.14 25.97C13.68 24.81 13.03 24.5 13.03 24.5C12.12 23.88 13.1 23.9 13.1 23.9C14.1 23.97 14.63 24.93 14.63 24.93C15.5 26.45 16.97 26 17.54 25.76C17.63 25.11 17.89 24.67 18.17 24.42C15.95 24.17 13.62 23.31 13.62 19.5C13.62 18.39 14 17.5 14.65 16.79C14.55 16.54 14.2 15.5 14.75 14.15C14.75 14.15 15.59 13.88 17.5 15.17C18.29 14.95 19.15 14.84 20 14.84C20.85 14.84 21.71 14.95 22.5 15.17C24.41 13.88 25.25 14.15 25.25 14.15C25.8 15.5 25.45 16.54 25.35 16.79C26 17.5 26.38 18.39 26.38 19.5C26.38 23.32 24.04 24.16 21.81 24.41C22.17 24.72 22.5 25.33 22.5 26.26V29C22.5 29.27 22.66 29.59 23.17 29.5C27.14 28.16 30 24.42 30 20C30 18.6868 29.7413 17.3864 29.2388 16.1732C28.7362 14.9599 27.9997 13.8575 27.0711 12.9289C26.1425 12.0003 25.0401 11.2638 23.8268 10.7612C22.6136 10.2587 21.3132 10 20 10Z" fill="black"/>
                 </svg>
                 Github code

                  </a>
                  
                    : null}  
                  {link0 ? <li>{link0}</li>: null}
                  { link1? <li> <a className="underline" target="_blank"  href={href1}>{link1}</a></li>: null}
                  { link2 ?<li> <a className="underline" target="_blank" href={href2}>{link2}</a></li>: null}
               </ul>

               <iframe className='h-full w-full lg:w-2/3 rounded-2xl overflow-hidden aspect-video' width="560" height="315" src={video2} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    </div>
  )
}

export default Seccion_ImgVideo