import React from 'react';
import Navbar from '../NavBar';
import Footer from '../components/footer';
import Portada_Interna from '../components/portada_interna';
import ButtonUp from '../components/buttonUp';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';

const DynamicPhotography = ({ id , images}) => {
  
  return (
    <div className='flex flex-col w-full h-full relative   bg-primary-500 '>
    <img className=" w-full h-full select-none object-cover aspect-[4/3]  absolute left-0 top-0  z-10 flex test" loading="lazy" src={"/png/bg_large.png"} alt={"bg"} />
     
       <Navbar />
    <div className='flex flex-col items-center text-black gap-12    pt-10  pb-20'>

    <Portada_Interna
        title={id}
        
        />
    <ButtonUp/>

   <ResponsiveMasonry className='w-full wrapper relative z-10'
     columnsCountBreakPoints={{350: 1, 750: 2, 1280: 3}}
           >
               <Masonry gutter="20px">
              
               {images.map((image, index) => (
                <img
                loading='lazy'
                className=' '
                src={image}
                alt={id+index}
              />
               ))}
               </Masonry>
   </ResponsiveMasonry>
   </div>
     <Footer/>
   </div>
  );
};

export default DynamicPhotography;
