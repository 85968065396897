import React from 'react'
import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Seccion_ImgDerecha from '../components/seccion_imgderecha'
import Seccion_ImgIzquierda from '../components/seccion_imgizquierda'
import ProgressBar from '../components/progressbar'
export default function Physics() {
 

  const linksPhy = [
    {
    name:"Learning quantum  ", 
    href:"#Reinforcement"
    },
    {
    name:"Quantum computing", 
    href:"#quantum_computing"
    },
    {
    name:"Quanum-sensing", 
    href:"#quanum_sensing"
    }
  ]
  return (  
    <div className='flex flex-col w-full  h-full relative  bg-primary-500 '>
     
     <img className=" w-full h-full select-none object-cover aspect-[4/3] absolute left-0 top-0  z-10 flex test " loading="lazy" src={"/png/bg_large.png"} alt={"bg"} />
     <Navbar />
    
     <ButtonUp/>
      <div className='flex flex-col text-black gap-12  items-center   pt-10  pb-20   '>
      <Portada_Interna
        title={"Physics & Artificial Intelligence"}
        page={"escritos"}
        description={"In the following I will describe the research projects I am currently involved in. Each is an ongoing project, and while all of them have preliminary results, I am super open to collaborate, so reach out to me if interested!        "}
        />

        <ProgressBar
        linksList={linksPhy}
        />

        <div className='flex flex-col gap-32  lg:px-28 xl:px-0'>
        <Seccion_ImgDerecha
          id="Reinforcement"
          title={"Reinforcement learning quantum discrimination protocols          "}
          img={"/png/miro_01.png"}
          phg1={"Have you ever wondered how we communicate with the robots on Mars? Or how we would even communicate to a place that is further away, like a satellite exiting the Milky Way? Well, that’s usually done using lasers! However, the intensity of the laser (how strong the signal containing the information is) tends to decrease due to many reasons. At some point, the signal is so weak that quantum-mechanical effects become extremely relevant."}
          phg2={" In this project, we ask how AI can help us to extract the information encoded into a quantum state. It turns out that this is not a trivial task, and even less trivial is to implement it in a satellite! Among other things, we study how Reinforcement Learning (a branch of machine learning that works, more or less, as training your dog) can do in discovering new ways to perform quantum measurements, even in conditions which are completely unknown to the robots (or to us!).          "}
          phg6={"  You can find more information about it in our papers:"}

          link1={"Real-time calibration of coherent-state receivers: Learning by trial and error"}
          href1={"https://journals.aps.org/prresearch/abstract/10.1103/PhysRevResearch.2.033295"}
          link2={"Reinforcement-learning calibration of coherent-state receivers on variable-loss optical channels"}
          href2={"https://ieeexplore.ieee.org/document/9611396"}
        />

         <Seccion_ImgIzquierda
          id="quantum_computing"
          title={"Quantum computing & NISQ circuits          "}
          img={"/png/miro_02.png"}
          phg1={"If you haven’t heard about quantum computers, I’m not sure if we are living in the same decade! In a nutshell, quantum computers exploit the principles of quantum-mechanics to process a very complex kind of information: quantum information. This just means that if we want to understand molecules, proteins and the evolution of atoms or so, we just use the principles of quantum-mechanics to process all the information. Because the amount of information that such systems contain is so, so large, it is practically impossible to analyze this data using our normal computers (or even using a super-computer).           "}
          phg2={" In the last three decades, very smart people have studied and propose a machine called “quantum computer”, which exploits certain phenomena occurring in quantum physics (like superposition and entanglement) allowing to process quantum information in a much more efficient way.           "}

          phg3={"However, building such a machine turns out to be a challenge. Perhaps one of the biggest technological challenges that humanity has ever faced. Perfectly controlling a quantum system (like a bunch of atoms) is really hard, imagine how fragile an atom can be! There is a still a really long road until we can construct a proper quantum computer.          "}

          phg4={"  Nevertheless, there is actually something that experimentalists are doing NOW. While not a proper quantum computer, the possibility of (imperfectly) controlling a bunch of atoms is already here, and the question of how well can we manipulate the information and perform some computations raises.          "}

          phg5={"In this project, we develop AI algorithms to control the different pieces that conform to a Noisy Intermediate-Scalable Quantum (NISQ) circuit, in order to do something useful. In words, that means that a robot needs to figure out a way to make one of the (imperfect) quantum computers that we have available to do something. In our simulations, it really seems to work!          "}

          phg6={"You can check out our paper, and code implementation!"}

          link1={"A semi-agnostic ansatz with variable structure for quantum machine learning "}
          href1={"https://arxiv.org/abs/2103.06712"}
          link2={"GitHub code"}
          href2={"https://github.com/matibilkis/qvans"}
        />


        <Seccion_ImgDerecha
        id={"quanum_sensing"}
          title={"Quanum-sensing & continuously-monitored systems          "}
          img={"/png/miro_03.png"}
          phg1={"You have probably heard of the Schrödinger cat: that’s a cat in a box, being in a superposition of alive and dead. The story goes on by opening the box and the cat “collapsing” to either one of the possible results (hopefully it dies, since I hate cats: I’m more of a dog person).          "}
          phg2={" However, instead of fully opening the box, and letting the surroundings to fully interact with the cat, and then us with the surroundings (photons) and acquiring information about the cat's life status, quantum physics allows for something else. One the one extreme the Schödinger cat described above sits, while the other is just to let the box closed. In between, we can peer at the cat, and this will modify its state (such superposition) by a little. This is known as quantum monitoring, and is a very cool phenomena that only happens in quantum mechanics! By the sole fact of acquiring information out of a quantum state, the system gets modified, what is known as quantum backaction.          "}
          phg3={"In many ways, monitoring a quantum system is useful. To begin with, one would like to know where a certain atom is! However, since by measuring the position of an atom one acquires information about it, the state changes, and thus its position: it’s a bit like trying to catch the wet soap in the shower! Moreover, by acquiring information about an atom's position, one might infer different things about the surroundings, like sensing electromagnetic fields. In turn, this allows us to monitor the brain/cardiac activity of our bodies, and people are already developing prototypes for atomic magnetic sensors.          "}
          phg4={"Opposite to the quantum computing scenario, where the fragility was a stone in the path to develop the quantum machine, in quantum sensing such atom fragility is crucial. In fact, people has demonstrated that using quantum sensors one can attain a much higher sensibility, and thus measure very weak signals more efficiently (like gravitational waves, which is done at LIGO and many other places around the globe).          "}
          phg5={"In this project, we focus on continuously peering at the quantum system: instead of using the fragility (complemented with effects like entanglement), we let the system evolve in time, and acquire a measurement signal in the meantime. Such a measurement signal contains information of the surroundings of the quantum system of interest, and we here care on how well this information can be understood by a machine-learning agent. Such information can either be the temperature in the surroundings of the cavity, or to describe the dynamics of an external magnetic field (like the heart beats to detect some arrhythmia).  "}
          phg6={"  You can check out more about this project in the following links:"}
          link0="Sequential hypothesis testing in continuously-monitored systems (coming soon!)          "
          link1={"Machine learning to estimate parameters in continuously-monitored systems          "}
          href1={"https://github.com/matibilkis/cdisc/blob/estimation/poster/comoposter/main.pdf"}
          link2={"Github repo with many branches :)"}
          href2={"https://github.com/matibilkis/continuous"}
        />
        </div>
      </div>
      <Footer/>
    </div>
  )
}
