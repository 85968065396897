import React from "react";

import LinkMenu from "./linkMenu";
import { Link } from "react-router-dom";

const Portada_Interna =({title, description})=>{
  

    return(
        <div className=" w-full h-full flex flex-col  wrapper lg:px-28 xl:px-0">
           <div className={"relative flex flex-col gap-4 justify-center w-full"}>
                <div className="flex flex-col gap-2  lg:max-w-full">
                    <h1 className="capitalize relative z-10 text-black text-5xl">{title}</h1>
                    {description ? <p className="text-black">{description}</p>: null}
                </div>
           </div>
        </div>
    )
}

export default Portada_Interna