import React from "react";

import LinkMenu from "./linkMenu";
import { Link } from "react-router-dom";
import Footer from "./footer";

const Portada =({links, img, imgMobile, imgAlt, title})=>{
  

    return(
        <div className="w-full h-full   flex items-center justify-center relative py-10">
          <img
            className=" w-full h-full lg:h-auto select-none object-cover aspect-[4/3] absolute left-0 top-0
            lg:bottom-0  z-0 flex test"
            loading="lazy"
            src={img}
            alt={imgAlt}
        />
           <div className="relative wrapper flex flex-col  gap-4 justify-between  items-center  w-full h-full z-0 lg:px-28 xl:px-0">
               <div className="flex flex-col lg:flex-row items-center  gap-10  w-full h-full ">
                    <div className="flex flex-col gap-10 items-start w-full lg:w-2/3 pb-40">
                       <div className="flex flex-col gap-4 ">
                           <h1 className="relative z-10 text-black">{title}</h1>
                            <p>I am an argentinian physicist, based in Barcelona city. I am an expert in intersecting quantum physics with artificial intelligence. My PhD thesis <a href="https://drive.google.com/file/d/1se8t7J-68Yr_K-4lq_TZO94QsO3_eEjR/view?usp=sharing" className="underline" target="_blanck">(check it out!)</a> is entitled “Decision-making in quantum environments”, where I study different quantum technology frameworks (communication, computing and sensing) under which the symbiosis of quantum AI takes place.
                            <br /><br />

                            Aside from quantum machine learning, I am strongly interested in outreach, with a particular focus on explaining quantum physics through artistic installations to the general audience. 
                            I have many hobbies, and aside from selling myself in order to get money, in this webpage I intend to share some of the fruits that came out during the last couple of years!
                            </p>
                       </div>

                       <Link to={"/contact"} className="border border-black bg-secondary-500 hover:bg-secondary-600 text-black  px-8 py-1 rounded-2xl">Contacto</Link>
                    </div>
                 <img className="opacity-70 sm:opacity-100  select-none w-full lg:w-1/2 h-full object-contain aspect-[4/3] flex absolute -bottom-1/2 lg:bottom-0 -z-10 lg:relative lg:z-10" src={imgMobile} alt={imgAlt} />
               </div>
               
           </div>

        </div>
    )
}

export default Portada