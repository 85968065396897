import React from 'react'

import Portada_Interna from '../components/portada_interna'
import Navbar from '../NavBar'
import Footer from '../components/footer'
import ButtonUp from '../components/buttonUp'
import Seccion_ImgDerecha from '../components/seccion_imgderecha'
import Seccion_ImgIzquierda from '../components/seccion_imgizquierda'
import ProgressBar from '../components/progressbar'
import Seccion_ImgVideo from '../components/seccion_imgvideo'

export default function ScienceArt() {
  const linksAudiovisuales = [
    {
    name:"Cine Expandido", 
    href:"#cine_Expandido"
    },

    {
    name:"Ejecicios Nocturnos", 
    href:"#ejercicios_nocturnos"
    },
    {
    name:"Purga ", 
    href:"#purga"
    },

    {
    name:"Selva", 
    href:"#selva"
    },
    {
    name:"The Edge of the Void ", 
    href:"#the_edge_of_the_void"
    },
    {
    name:"Jean Genet Ahora", 
    href:"#jean_genet_ahora"
    },

    {
    name:"Lennons", 
    href:"#lennons"
    },
    {
    name:"Ofrenda", 
    href:"#ofrenda"
    },
]



const linksPhy = [
 
  {
  name:"Learning the art & the art of learning: making music by trial and error  ", 
  href:"#learning"
  },
  {
    name:" La foscor & the quantum learners  ", 
    href:"#foscor"
    },

]
return (  
  <div className='flex flex-col w-full  h-full relative  bg-primary-500'>
   
   <img className=" w-full h-full select-none object-cover aspect-[4/3] absolute left-0 top-0  z-10 flex test" loading="lazy" src={"/png/bg_large.png"} alt={"bg"} />
   <Navbar />
  
   <ButtonUp/>
    <div className='flex flex-col text-black gap-12  items-center   pt-10  pb-20  '>
    <Portada_Interna
      title={"Science & Arts"}
      description={"In the following I will describe the research projects I am currently involved in. Each is an ongoing project, and while all of them have preliminary results, I am super open to collaborate, so reach out to me if interested!        "}
      />

      <ProgressBar
      linksList={linksPhy}
      />

      <div className='flex flex-col gap-32  lg:px-28 xl:px-0'>
      <Seccion_ImgVideo
        id="learning"
        title={"Learning the art & the art of learning: making music by trial and error        "}
        video1={"https://www.youtube.com/embed/Y1urPDJekxY?start=1425"}
        video2={"https://www.youtube.com/embed/IWFlugoLHFU?start=13110"}
       
        phg1={"How much do we assert by denying? Does beauty emerge from symmetry, or rather from        the lack of it? Where is the frontier between casuality and causality? What are the       our creation? What are the limits of our creativity?              "}
        phg2={" This project takes place between these questions and the lack of their answer. With         intention of making room for debate on what it means to do science and/or art, we          perform a little experiment where Artificial Intelligence (AI) agents interact with in real-time. The agents will first learn the instructions given by the musician,  subsequently collaborate with each other in order to create a musical piece. The  how creation, beauty, mistakes and the lack of answers relate with each other will         along this journey.           "}
        phg3={"If you are interested in developing this project further, reach out to me! I am willing to create a physical platform to interact with, as if it was a new (machine-learning) instrument 🙂        "}
        phg6={"  Find out more:        "}
        gitRepo={"https://github.com/matibilkis/hackaton_AI_music"}
       
      />

       <Seccion_ImgIzquierda
        id="foscor"
        title={"La foscor & the quantum learners        "}
        img={"/png/miro_02.png"}
        phg1={"In this project we invite society to interact with quantum physics. Our project lies on the interplay between the audience and (simulated) quantum objects, and we propose a bunch of games, since we are convinced that - even when grown old - one of the best ways to learn is by playing! In these lines, we will set up several installations allowing people to get some insight of what concepts like quantum entanglement of quantum back-action are, by help of the artistic language.        "}
        phg2={" By involving society lying outside scientific community, we would like to set some debates that (we believe) are important for us to have. Our goal is not only to get some take-home messages (like is quantum physics all about!) but also some questions, like what are the limits that technology imposes to our society? And also, (why not?) which are the limits that we as society impose to new technologies. Moreover, we would like to invite the community of artists to help us spread knowledge about quantum physics.        "}


        phg6={"We are currently looking for funding for this project, so if you know something that might be of interest, please share that with us!        "}

        
      />


      
      </div>
    </div>
    <Footer/>
  </div>
)
}
