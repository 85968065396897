import React, { useEffect, useState } from 'react';
import Portada from '../components/portada'
import Footer from '../components/footer';
import Navbar from '../NavBar';

const useHeightBasedImage = () => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      const handleResize = () => {
        const { innerHeight } = window;
        
        if (innerHeight < 800) {
          setImageUrl('/png/bg_miro.png');
        } else {
          setImageUrl('/png/bg_miro.png');
        }
      };
  
      handleResize(); // Se ejecuta al cargar la página
  
      window.addEventListener('resize', handleResize); // Se suscribe al evento de cambio de tamaño de ventana
  
      return () => {
        window.removeEventListener('resize', handleResize); // Se limpia el evento al desmontar el componente
      };
    }, []);
  
    return imageUrl;
  };


function Home()  {
    const linksHome = [
        {
        name:"Teatro Danza", 
        href:"/teatro"
        },
        {
        name:"Acrobacia", 
        href:"/acrobacia"
        },
        {
        name:"Audiovisuales", 
        href:"/audiovisuales"
        },
        {
        name:"Prod. Fotográficas", 
        href:"/fotografias"
        },
        {
        name:"Escritos", 
        href:"/escritos"
        }
    ]
    const imageUrl = useHeightBasedImage();

    return (
        
   <div className='flex flex-col   w-full h-full md:h-screen md:overflow-hidden bg-primary-500'>
     <Navbar />
     <div className='w-full  h-full md:h-[90%] overflow-hidden  bg-primary-500'>
      <Portada
          links={linksHome}
          img={imageUrl}
          imgMobile={"/png/matias_bilkis.png"}
          title={"Matías Bilkis"}/>
           
     </div>
     <Footer/>   
   </div>
    );
    }

export default Home;