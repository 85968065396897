import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import Navbar from '../NavBar';
import PortadaContacto from '../components/portada_contacto';

const useHeightBasedImage = () => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      const handleResize = () => {
        const { innerHeight } = window;
        
        if (innerHeight < 800) {
          setImageUrl('/png/bg_miro.png');
        } else {
          setImageUrl('/png/bg_miro.png');
        }
      };
  
      handleResize(); // Se ejecuta al cargar la página
  
      window.addEventListener('resize', handleResize); // Se suscribe al evento de cambio de tamaño de ventana
  
      return () => {
        window.removeEventListener('resize', handleResize); // Se limpia el evento al desmontar el componente
      };
    }, []);
  
    return imageUrl;
  };


function Contact()  {
    const linksHome = [
        {
        name:"Teatro Danza", 
        href:"/teatro"
        },
        {
        name:"Acrobacia", 
        href:"/acrobacia"
        },
        {
        name:"Audiovisuales", 
        href:"/audiovisuales"
        },
        {
        name:"Prod. Fotográficas", 
        href:"/fotografias"
        },
        {
        name:"Escritos", 
        href:"/escritos"
        }
    ]
    const imageUrl = useHeightBasedImage();

    return (
        
   <div className='flex flex-col  w-full md:h-screen md:overflow-hidden bg-primary-500'>
     <Navbar />
     <div className='w-full h-[90%] overflow-hidden   bg-primary-500'>
      <PortadaContacto
          img={imageUrl}
          imgMobile={"/png/matias_bilkis.png"}
          title={"Let's talk"}/>
           
     </div>
     <Footer/>   
   </div>
    );
    }

export default Contact;